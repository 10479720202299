.request{
    display: flex;
    width: 90%; 
    margin: auto;
    margin-top:16px
}
.footer{
    margin-top: 20px;
    background-color: #f8f9fa;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    top:50px;
    width:37%
}
.vertical-collpsed {
    .footer {
        width: 41%;
    }
}
#response{
    width: 100%;
    height: 90%;
    overflow:auto;
    padding:10px;
    font-family: monospace;
    white-space:pre-wrap;
    box-sizing: border-box;
    word-wrap: break-word;
}

.request-box{
    height:35%;
    overflow:hidden;
    margin:2% 0;
}

.response-box{
    height:62%;
    overflow:hidden;
    margin:2% 0
}

@media (max-width: 500px) {
    .request-box{
        height:300px;
    }
    
    .response-box{
        height:300px;
    }
}