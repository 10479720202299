// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}

.btn-light{
  border: 1px solid darken($light, 2%);
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}

// @media (max-width:540px){
//   .btn-info{
//   font-size: 8px;
//   }
//   .request{
//     width: 91% !important;
//     }
// }
@media (max-width:850px){
  .btn-info{
    font-size: 9px;
    padding: 0px;
    }
}