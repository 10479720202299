// 
// _footer.scss
// 

.footer {
    // top: 58%;
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    color: $footer-color;
    // left: $sidebar-width;
    box-shadow: $box-shadow;
    background-color: $footer-bg;
    // overflow-x: scroll;
}

@media (max-width: 500px) {
    .footer {
        width: 100% !important;
        top: unset !important;
        bottom: unset !important;
    }
    .request-content{
        width: 100% !important;
    }
}
.request-content{
    width: 55%;
}
// Enlarge menu
// .vertical-collpsed {
//     .footer {
//         left: $sidebar-collapsed-width;
//     }
// }

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}